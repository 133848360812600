import React, { useState, useEffect } from 'react';
import { Container } from "react-bootstrap"
import { graphql, useStaticQuery } from 'gatsby';
import PropertyGridCard from './PropertyGridCard';  
import OffPlanCard from './OffPlanCard';
import Slider from 'react-slick'; 
import { SlickPrevIcon, SlickNextIcon } from '../../Icons/Icons';
import "./FeaturedProperties.scss";

const FeaturedProperties = ({ place, searchType }) => {
  const [activeTab, setActiveTab] = useState(searchType || 'sales'); // Default to search type or 'sales'
  const [properties, setProperties] = useState([]);

  const featuredpropQuery = useStaticQuery(graphql`
    query featuredproperties {
      glstrapi {
        sales: properties(where: {publish:true, featured_property: "Y", search_type: "sales"}, limit: 12) {
          id
          address
          images
          imagetransforms
          featured_property
          display_address
          display_area
          bathroom
          bedroom
          floor_area
          price
          publish
          slug
          property_url
          search_type
          published_at
          extras
          title
        }
        lettings: properties(where: {publish:true, search_type: "lettings"}, limit: 12) {
          id
          address
          images
          imagetransforms
          featured_property
          display_address
          display_area
          bathroom
          bedroom
          floor_area
          price
          publish
          slug
          property_url
          search_type
          published_at
          extras
          title
        }
        offplan: offPlanProperties(where: {Publish: true}, limit: 12) {
          id
          Title
          URL
          Price
          Location
          Developer
          createdAt
          Developer_Type
          Tile_Image {
            alternativeText
            url
          }
          imagetransforms
        }
      }
    }
  `);

  useEffect(() => {
    const fetchProperties = (tab) => {
      //console.log('Fetching properties for tab:', tab);
      if (tab === 'sales') {
        setProperties(featuredpropQuery.glstrapi.sales);
      } else if (tab === 'lettings') {
        setProperties(featuredpropQuery.glstrapi.lettings);
      } else if (tab === 'offplan') {
        setProperties(featuredpropQuery.glstrapi.offplan);
      }
    };

    fetchProperties(activeTab);
  }, [activeTab, featuredpropQuery]);

const handleTabChange = (tab) => {
  console.log('Changing tab to:', tab);
  setActiveTab(tab); 
  setProperties([]);
};

const renderImage = (item) => {
  let imageUrl = "";
  let processedImages = {};

  if (activeTab === 'offplan') {
    imageUrl = item.Tile_Image?.url || "";
    processedImages = item.imagetransforms?.Tile_Image_Transforms || {};
  } else {
    imageUrl = item.images?.[0]?.url || "";
    processedImages = item.imagetransforms?.images_Transforms || {};
  }
  
  return { imageUrl, processedImages };
};

  const NextArrow = ({ onClick }) => {
    return (
      <button className="slick-next" onClick={onClick}>
        <SlickNextIcon />
      </button>
    );
  };
  
  const PrevArrow = ({ onClick }) => {
    return (
      <button className="slick-prev" onClick={onClick}>
        <SlickPrevIcon />
      </button>
    );
  };

  const settings = {
    dots: false,
    lazyLoad: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 3,
    infinite: true,
    arrows: true, 
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    mobileFirst: true,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1520,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          dots: true, 
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true, 
        },
      },
    ],
  };

  return (
    <div className="featured-properties-wrap sec-pdy-120">
      <Container>
       <div className="featured-heading">
              <h4>{place? `Featured properties in ${place}`: `Featured properties`}</h4>
        </div>
        <div className="featured-properties">
            <div className="tab-navigation">
                <button onClick={() => handleTabChange('sales')} className={`nav-item ${activeTab === 'sales' ? 'active' : ''}`}>For Sale</button>
                <button onClick={() => handleTabChange('lettings')} className={`nav-item ${activeTab === 'lettings' ? 'active' : ''}`}>For Rent</button>
                <button onClick={() => handleTabChange('offplan')} className={`nav-item ${activeTab === 'offplan' ? 'active' : ''}`}>Off Plan</button>
            </div>
            {properties.length === 0 ? (
              <p>No properties available for this category.</p>
            ) : (
              <Slider {...settings} key={activeTab}>
                {properties.map((item) => {
                  const { imageUrl, processedImages } = renderImage(item);
                  // Assuming card component can accept imageUrl and processedImages props
                  return activeTab === 'offplan' ? (
                    <OffPlanCard key={item.id} item={{ ...item, imageUrl, processedImages }} />
                  ) : (
                    <PropertyGridCard key={item.id} item={{ ...item, imageUrl, processedImages }} />
                  );
                })}
              </Slider>
            )}
        </div>
        </Container>
    </div>
  );
};

export default FeaturedProperties;