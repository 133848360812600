import React from 'react';
import { Link } from "@StarberryUtils";
import _ from "lodash"
import { useLocation } from "@reach/router"
import ModalTiny from "react-bootstrap/Modal"
import Form from "../../forms/book-a-viewing-form"
import { PhoneIconBlue, EnvelopeIconBlue, WhatsAppIconBlue, CloseIcon } from  "../../Icons/Icons";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import "./PropertyGridCard.scss";

const PropertyGridCard = (props) => {
    const location = useLocation();
    
    var location_link = process.env.GATSBY_SITE_URL+location.pathname;
    var whatsapp_link = `https://wa.me/97144232006?text=Hello!%20I%20want%20to%20know%20more%20about%20${location_link}`
      if(location.search.indexOf("utm_source") > 0){
          whatsapp_link = `https://wa.me/97144232006?text=*G*%20Hello!%20I%20want%20to%20know%20more%20about%20${location_link}`
      }
      
    const getPOA = (data, price) => {
        var permit = 'AED ' + price.toLocaleString();
        if (!_.isEmpty(data?.POA)) {
          if (data.POA == "Y") {
            permit = "PRICE ON APPLICATION";
          }
        }
        return permit;
      }
      const formatNumberWithComma = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      };
 
    // property details url structure
    let uriStr = "";

    if (props?.item.search_type === "sales") {
        uriStr = `properties-for-sale/`
    } else if (props?.item.search_type === "lettings") {
        uriStr = `properties-for-rent/`
    }

    if (props.tag == "commercial") {
        if (props?.item.search_type === "sales") {
        uriStr = `commercial-properties-for-sale/`
        } else if (props?.item.search_type === "lettings") {
        uriStr = `commercial-properties-for-rent/`
        }
    }

    if (props.tag == "new-homes") {
        if (props?.item.search_type === "sales") {
        uriStr = `new-homes-for-sale/`
        } else if (props?.item.search_type === "lettings") {
        uriStr = `new-homes-for-rent/`
        }
    }
    // property details url structure

   
    // var image_url = "img01";
    // if (!_.isEmpty(props?.item.images)) {
    //     image_url = props?.item.images[0].url
    // }

    // let processedImages = JSON.stringify({});
    // if (props?.item?.imagetransforms?.images_Transforms) {
    //     processedImages = props?.item.imagetransforms.images_Transforms;
    // }
    const [modalAskformOpen, setAskformOpen] = React.useState(false);
    const openAskformModal = () => {
        setAskformOpen(true);
      }
      const closeAskformModal = () => {
        setAskformOpen(false);
      }
  return (
    <>
    <div className="property-grid-card-wrap">
      <div className="properties-img-wrapper img-zoom">
        <Link to={`/${uriStr}${props?.item?.slug}-${props?.item?.id}`}>
          <ImageTransform
            crEnv="i.prod" //remove when moving live
            imagesources={props?.item?.imageUrl}
            renderer="srcSet"
            imagename="property.images.similar_properties"
            attr={{ alt: `${props?.item.address?.area} - Dacha`, class: '', loading: 'eager' }}
            imagetransformresult={props?.item?.processedImages}
            id={props?.item.id}
          />
        </Link>
      </div>
      <div className="property-grid-card-info">
        <div className="properties-info">
          <h2 className="text-md-bold-20-16">
            <Link to={`/${uriStr}${props?.item.slug}-${props?.item.id}`}>{props?.item.title}</Link>
          </h2>
          <div className="price text-md-bold-20-16">{props?.item.price ? getPOA(props?.item.extras, props?.item.price) : ''}</div>
          <div className="about-location">
            <span><i className="icon-location"></i></span>
            <span className="location-info text-xxs">
              {props?.item?.address?.Address && `${props?.item?.address?.Address}, `}
              {props?.item?.address?.area && `${props?.item?.address?.area}, `}
              {props?.item?.address?.town}
            </span>
          </div>
          {props?.item.bedroom !== 0 || props?.item.bathroom !== 0 ? (
            <div className="properties-icons">
              {props?.item.bedroom !== 0 && (
                <div className="text">
                  <span><i className="icon-bedroom"></i></span>
                  <span className="text-xxs-bold">{props?.item.bedroom}</span>
                </div>
              )}
              {props?.item.bathroom !== 0 && (
                <div className="text">
                  <span><i className="icon-bath"></i></span>
                  <span className="text-xxs-bold">{props?.item.bathroom}</span>
                </div>
              )}
              {props?.item.floor_area !== 0 && (
                <div className="text">
                  <span><i className="icon-space"></i></span>
                  <span className="text-xxs-bold">{props?.item.floor_area ? formatNumberWithComma(props?.item.floor_area) :''} sq.ft</span>
                </div>
              )}
            </div>
          ) : (
            <div className="properties-icons">&nbsp;</div>
          )}
        </div>

        <div className="agent-contact-info">
            <a href={`tel:${props?.item?.Phone || "+971 4 423 2006"}`} className="agent-number text-xxs btn btn-light-blue">
                <span><PhoneIconBlue className="icon-contact-info" /></span>
                <span>Call</span>
            </a>
          <a href="javascript:void(0)" onClick={openAskformModal} className="text-xxs btn btn-light-blue">
            <span><EnvelopeIconBlue className="icon-contact-info" /></span>
            <span>Email</span>
          </a>
          <Link href={whatsapp_link} className="text-xxs btn btn-light-blue" target="_blank">
            <span><WhatsAppIconBlue className="icon-contact-info" /></span>
            <span className="d-none">WhatsApp</span>
          </Link>
        </div>
      </div>
    </div>
     <ModalTiny show={modalAskformOpen} onHide={closeAskformModal} className="modal-form-wrapper people-details-form-modal-main">
     <ModalTiny.Header >
     <div class="modal-close-btn" onClick={closeAskformModal}><CloseIcon /></div>
       <ModalTiny.Title><h4>{props?.Name ? `Contact ${props?.Name}` : `Book an Appointment`}</h4></ModalTiny.Title>
     </ModalTiny.Header>
     <ModalTiny.Body >
       <Form peopleName={props?.Name} />
     </ModalTiny.Body>
   </ModalTiny>
   </>
  );
};

export default PropertyGridCard;